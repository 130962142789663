<script setup lang="ts">
const { t } = useI18n({ useScope: 'local' })
const localePath = useLocalePath()
</script>

<template>
  <footer data-x-feature="footer" class="px-4 pt-16 text-white bg-violet-900 md:px-6 md:pt-20 pb-28">
    <div class="mx-auto max-w-7xl">
      <div class="flex items-center justify-center sm:justify-between">
        <img
          src="/logo/full-white.svg"
          width="201"
          height="40"
          alt="AppGuide"
        >

        <div>
          <p class="hidden font-display font-bold text-right lg:text-2xl sm:block text-balance max-w-md">
            {{ t('mission') }}
          </p>
        </div>
      </div>

      <hr class="my-12 border-t-8 md:mb-16 border-violet-700">

      <div class="flex flex-col items-center md:items-start md:flex-row-reverse">
        <div class="mb-12 md:mb-0">
          <UtilsLangSwitcher direction="top-left" dark-mode />
        </div>

        <div class="flex flex-col items-center md:mr-auto md:items-start md:flex-row md:space-x-6">
          <img src="/logo/therappx-violet.svg" width="64" height="32" alt="TherAppX logo">

          <div class="mt-4 text-sm text-center md:mt-0 md:text-left">
            <p class="mb-1">
              {{ t('powered-by') }}
              <!-- <a href="https://therappx.com" rel="noopener noreferrer" target="_blank" class="font-semibold underline">TherAppX</a> --><span class="font-semibold">TherAppX</span>,
              &copy; {{ new Date().getFullYear() }} TherAppX
            </p>

            <p class="mb-5">
              {{ t('for-technical-problem[0]') }}<br>
              {{ t('for-technical-problem[1]') }}
              <a
                data-x-action="mailto"
                href="mailto:support@therappx.com"
                class="font-semibold underline hover:no-underline"
              >support@therappx.com</a>
            </p>

            <div class="flex flex-col gap-5 font-semibold md:flex-row md:gap-6">
              <NuxtLink data-x-name="terms-and-conditions" :to="localePath('terms-and-conditions')" class="underline hover:no-underline">
                {{ t('terms-and-conditions') }}
              </NuxtLink>
              <NuxtLink data-x-name="privacy-policy" :to="localePath('privacy-policy')" class="underline hover:no-underline">
                {{ t('privacy-policy') }}
              </NuxtLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<i18n lang="json">
{
  "en": {
    "mission": "Recommend a qualified self-care tool for each patient",
    "powered-by": "AppGuide is powered by",
    "for-technical-problem": [
      "For any technical problem, confidentiality issue or to report any health problem related to the use",
      " of AppGuide, please contact us at "
    ],
    "terms-and-conditions": "Terms and conditions",
    "privacy-policy": "Privacy policy"
  },
  "fr": {
    "mission": "Recommander un outil d'autosoins qualifié à chaque patient",
    "powered-by": "AppGuide est propulsé par",
    "for-technical-problem": [
      "Pour tout problème technique, question de confidentialité ou pour signaler tout problème de santé lié",
      " à l'utilisation d'AppGuide, merci de nous contacter à "
    ],
    "terms-and-conditions": "Termes et conditions",
    "privacy-policy": "Politique de confidentialité"
  }
}
</i18n>
